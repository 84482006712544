@import './global.scss';

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background-color: rgb(24, 107, 160);
}

.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
    background-color: rgb(24, 107, 160);
}

.p-inputswitch {
    .p-inputswitch-slider {
        -webkit-transition: background-color .3s, box-shadow .2s;
        transition: background-color .3s, box-shadow .2s;
        background: #cccccc;

        &:before {
            background-color: #ffffff;
        }
    }
}

.tr {
    padding: 2px;
}

.p-dropdown {
    border: 1px solid black;
    width: inherit;
    border-radius: 0;
}

.p-dropdown-label {
    padding: 0.2em;
}

.p-dropdown .p-dropdown-trigger {
    background-color: #0078ae;
    color: #ffffff;
    width: 1.5em;
    border-radius: 0;
}

.p-dropdown-trigger-icon {
    color: #F8F7F7;
}