@import './styles/banner.scss';
@import './styles/normalize.scss';
@import './styles/global.scss';
@import './styles/reprocessing.scss';
@import './styles/FileUpload.scss';
@import '../node_modules/primeflex/primeflex.scss';
// @import '../node_modules/primeng/resources/themes/lara-light-blue/theme.css';
@import '../node_modules/primeicons/primeicons.css';
@import '../node_modules/primeng/resources/themes/mdc-light-indigo/theme.css';
@import '../node_modules/primeng/resources/primeng.min.css';
@import "ag-grid-community/dist/styles/ag-grid.css";
@import "ag-grid-community/dist/styles/ag-theme-balham.css";