@import './global.scss';

.p-toolbar {
    background-color: rgb(33, 145, 192);
    border-radius: 0;
    padding-bottom: 4px;
    padding-left: 8px;
    padding-right: 4px;
    padding-top: 4px;
    zoom: 1;
    border-color: rgb(66, 151, 215);
}

.p-toolbar-group-right,
.p-toolbar-group-left {
    font-family: $menu-text;
    font-weight: bold;
}

#title {
    color: black;
    font-size: 28px;
}

.p-button.p-button-icon-only {
    background: $button-icon-color;
    border: 1px solid $button-icon-color;
    width: 2rem;
    height: 26px;
}

.p-menubar {
    padding: .25em;
    border: 1px solid #a6c9e2;
    background-color: $background-white;
    color: #222222;
}

.p-menuitem {
    background-color: $background-white;
    color: #222222;
    border-radius: 5px;
}

.p-menuitem-link {
    padding: .5em !important;
}

.p-menuitem-text {
    font-family: $menu-text;
    // font-size: 1em;
    vertical-align: middle;
}

.p-submenu-list {
    border-radius: 5px;
    border: 1px solid #a6c9e2 !important;
    width: 25em !important;
}

.p-fileupload {
    padding: 0.75rem 1.25rem;
    background-color: $background-white;
    color: #222222;
    border-radius: 5px;
}

.a {
    text-decoration-color: rgb(0, 0, 238);
}

.p-toolbar-group-right {
    justify-content: center;
}