$menu-text: Verdana, Arial, sans-serif;
$button-icon-color: #2191c0;
$background-white: #fcfdfd;

.ui-g-12 {
    padding: .5em;
}

.font-bold-18-px {
    font-size: 18px;
    font-weight: bold;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.margin-left-right-20px {
    margin-left: 20px;
}

.left {
    position: absolute;
    left: 0px;
    padding-left: 30px;
}

.margin-left-25px {
    margin-left: 25px;
}

.p-button {
    border: 1px solid #77d5f7;
    background-color: #0078ae;
    color: #ffffff;
    margin-right: .25em;
    color: #ffffff;
    padding: .25em 0.25em 0.25em 0.25em;

    &:enabled:hover {
        background-color: #79c9ec;
        border-color: #448dae;
        color: #222222;
    }

    &:focus {
        box-shadow: none;
    }
}

.button-with-icon {
    .p-button {
        padding: .25em 1em 0.25em 0.25em;
    }
}

.p-button-label {
    font-family: $menu-text;
    font-weight: 400;
}

.status-panel-text {
    margin-left: 6px;
    margin-right: 6px;
    padding-top: 12px;
    padding-bottom: 12px;
}

.ag-header-group-cell {
    font-weight: normal;
    font-size: 22px;
}

p-calendar {
    input[type="text"] {
        padding: 0.2em;
        border-radius: 0;
    }
}