@import './global.scss';

.p-fileupload {
    padding: 0;
    max-width: 40vw;
}

.p-fileupload .p-fileupload-buttonbar {
    background-color: #2191c0;
    padding: 0.5em;
}

.p-fileupload .p-fileupload-buttonbar .p-button {
    border: 1px solid #77d5f7;
    background-color: #0078ae;
    color: #ffffff;
    padding: 0;
    margin-right: .25em;
}

.p-fileupload .p-fileupload-buttonbar .p-button {
    border: 1px solid #77d5f7;
    background-color: #0078ae;
    color: #ffffff;
    padding: 0;
    margin-right: .25em;
    color: #ffffff;

    &:hover {
        background-color: #79c9ec;
        border-color: #448dae;
        color: #222222;
    }

    &:focus {
        box-shadow: none;
    }
}

.p-fileupload .p-fileupload-buttonbar .p-fileupload-choose.p-focus {
    border: 1px solid #77d5f7;
    background-color: #0078ae;
    color: #ffffff;
    padding: 0;
    margin-right: .25em;
    color: #ffffff;

    &:hover {
        background-color: #79c9ec;
        border-color: #448dae;
        color: #222222;
    }

    &:focus {
        box-shadow: none;
    }
}

.p-fileupload .p-fileupload-buttonbar .p-button .p-button-label {
    padding: .25em 1em .25em 0.25em;
    font-family: $menu-text;
}

.p-button .p-button-icon-left {
    padding-left: 0.75em;
    font-weight: bold;
}

.rag-green-outer {
    background-color: lightgreen !important;
}

.rag-red-outer {
    background-color: lightcoral !important;
}

.p-fileupload .p-fileupload-content {
    min-height: 200px;
    padding: 0 .5em;
}

.p-fileupload .p-fileupload-highlight {
    background-color: lightblue;
}

.p-fileupload-content>p-progressbar {
    display: none;
}

.val-errors {
    max-height: 500px !important;

    .ag-root-wrapper {
        max-height: 500px !important;

        .ag-body-viewport {
            max-height: 450px !important;
            overflow-y: auto !important;
        }
    }
}

.p-message {
    margin: 0;
}